<template>
  <div>
    <div class="title-tabs font-bold">Layout</div>
    <div class="bg-white p-3">
      <b-row>
        <b-col cols="12">
          <div class="f-18 font-bold text-black">Banner Detail</div></b-col
        >
        <b-col cols="6" class="mt-3">
          <UploadFile
            textFloat="Banner"
            placeholder="Please Choose File"
            format="image"
            :fileName="form.image_banner"
            v-model="form.image_banner"
            name="thumbnail"
            text="*Please upload only .png, .jpg image , ratio 1:1 and less than 10mb"
            isRequired
            :accept="acceptType"
            v-on:onFileChange="onImageChange"
            :disabled="isDisable"
            :isValidate="v.image_banner.$error"
            :v="v.image_banner"
          />
          <InputText
            textFloat="Button Label"
            placeholder=""
            isRequired
            type="text"
            v-model="form.button_label"
            :isValidate="v.button_label.$error"
            :v="v.button_label"
            name="button_label"
            customLabel
            :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 32);'"
          >
            <template v-slot:textFloat>
              <small class="count-text"
                >({{ form.button_label.length }}/32 Characters)</small
              >
            </template></InputText
          >
        </b-col>
        <b-col cols="6" class="my-3">
          <ImagePreview
            textFloat="Preview"
            :text="form.button_label"
            :bgImage="form.background_page"
            :image="form.image_banner"
            tabIndex="1"
            :isBanner="true"
          />
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12">
          <div class="f-18 font-bold text-black">Field Detail</div></b-col
        >

        <b-col cols="6" class="mt-3"
          ><div class="text-black">Field</div>
          <template>
            <b-row>
              <template v-for="(f, i) of fieldsDisplay">
                <b-col cols="12" md="4" :key="'select-' + i">
                  <InputText
                    textFloat=""
                    placeholder=""
                    isRequired
                    type="text"
                    :value="i == 0 ? 'Image' : `Field ${i}`"
                    :name="`Field-${i + 1}`"
                    disabled
                  />
                </b-col>
                <b-col cols="12" md="8" :key="'input-' + i">
                  <InputSelect
                    title=""
                    :name="`type-${i + 1}`"
                    isRequired
                    textField="name"
                    valueField="temp_index"
                    @onDataChange="
                      i == 0
                        ? handleImageSelect(i, $event)
                        : handleSelect(i, $event)
                    "
                    v-model="f.name"
                    :options="i == 0 ? optionsImage : options"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option value="" :disabled="i == 0"
                        >{{ i == 0 ? "Select Image Field" : "Select Field" }}
                      </b-form-select-option>
                    </template>
                  </InputSelect>
                </b-col>
              </template>
            </b-row>
          </template>
          <small class="text-black"
            >Card View จะแสดงผมทั้งหมด 6 Fields ส่วน List View จะแสดงทั้งหมด 4
            Fields เท่านั้น</small
          >
        </b-col>
        <b-col cols="6" class="my-3">
          <ImagePreview
            textFloat="Preview"
            :text="form.button_label"
            :bgImage="form.background_page"
            :image="form.image_banner"
            tabIndex="1"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ImagePreview from "./imagePreview";
export default {
  props: {
    form: { required: true },
    fields: { required: true },
    imageOptions: { required: true },
    data: { required: true },
    v: {
      required: true,
    },
  },
  components: { ImagePreview },
  data() {
    return {
      showPreview: "",
      showPreviewType: 0,
      acceptType: "image/jpeg, image/png, image/*",
      isDisable: false,
      fieldsDisplay: [
        {
          custom_object_field_id: 0,

          field_name: "Image",
          name: "1",
        },
        {
          custom_object_field_id: 0,
          field_name: "",
          name: "",
        },
        {
          custom_object_field_id: 0,
          field_name: "",
          name: "",
        },
        {
          custom_object_field_id: 0,
          field_name: "",
          name: "",
        },
        {
          custom_object_field_id: 0,
          field_name: "",
          name: "",
        },
        {
          custom_object_field_id: 0,
          field_name: "",
          name: "",
        },
      ],
      options: JSON.parse(JSON.stringify(this.fields)),
      optionsImage: JSON.parse(JSON.stringify(this.imageOptions)),
    };
  },
  created() {
    if (this.$route.params.id != 0) {
      for (let index = 0; index < this.fieldsDisplay.length; index++) {
        if (this.data[index]) {
          var a;
          if (index == 0) {
            a = this.imageOptions.find(
              (el) => el.name == this.data[index].field_name
            );
          } else {
            a = this.fields.find(
              (el) => el.name == this.data[index].field_name
            );
          }

          this.fieldsDisplay[index] = this.data[index];
          if (a) {
            this.fieldsDisplay[index].name = a.temp_index;
          }
        }
      }
      // this.$nextTick(() => {
      var options = [];
      for (const field of this.fields) {
        if (
          this.fieldsDisplay.find((el) => el.temp_index == field.temp_index)
        ) {
          field.disabled = true;
        } else {
          if (this.fieldsDisplay.find((el) => el.name == field.temp_index)) {
            field.disabled = true;
          } else field.disabled = false;
        }
        options.push(field);
      }
      this.options = options;
      // });
    }
  },
  watch: {
    fields(val) {
      this.options = JSON.parse(JSON.stringify(val));
      var i = 0;
      for (const fields of this.fieldsDisplay) {
        if (i != 0)
          if (!val.find((el) => el.name == fields.field_name)) {
            fields.field_name = "";
            fields.id = 0;
            fields.name = "";
          }
        i++;
      }
    },
    imageOptions(val) {
      console.log(val);
      this.optionsImage = JSON.parse(JSON.stringify(val));
    },
  },

  methods: {
    async onImageChange(val) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = () => {
          this.ratioMatch = true;
          this.setUpdataImage(reader.result);
        };
        img.src = await reader.result;
      };
    },
    async setUpdataImage(base64) {
      if (this.ratioMatch === true) {
        var url = await this.saveImagetoDb(base64, false);
        this.showPreviewType = 0;
        this.isLoadingImage = false;
        this.isDisable = false;
        this.showPreview = url;
        this.form.image_banner = url;
      }
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: false,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    handleSelect(i, value) {
      let index = this.fields.findIndex((el) => el.temp_index == value);

      this.$nextTick(() => {
        var options = [];
        for (const field of this.fields) {
          if (
            this.fieldsDisplay.find((el) => el.temp_index == field.temp_index)
          ) {
            field.disabled = true;
          } else {
            if (this.fieldsDisplay.find((el) => el.name == field.temp_index)) {
              field.disabled = true;
            } else field.disabled = false;
          }
          options.push(field);
        }
        this.options = options;
      });
      this.fieldsDisplay[i].custom_object_field_id = this.fields[index]
        ? this.fields[index].id
        : "";
      this.fieldsDisplay[i].field_name = this.fields[index]
        ? this.fields[index].name
        : "";
      this.fieldsDisplay[i].temp_index = this.fields[index]
        ? this.fields[index].temp_index
        : "";
    },

    handleImageSelect(i, value) {
      let index = this.imageOptions.findIndex((el) => el.temp_index == value);

      this.$nextTick(() => {
        var options = [];
        for (const field of this.imageOptions) {
          if (
            this.fieldsDisplay.find((el) => el.temp_index == field.temp_index)
          ) {
            field.disabled = true;
          } else {
            field.disabled = false;
          }
          options.push(field);
        }
        this.optionsImage = options;
      });
      this.fieldsDisplay[i].custom_object_field_id =
        this.imageOptions[index].id;
      this.fieldsDisplay[i].field_name = this.imageOptions[index].name;
      this.fieldsDisplay[i].temp_index = this.imageOptions[index].temp_index;
    },
  },
};
</script>

<style>
.count-text {
  position: absolute;
  top: 8px;
  right: 0;
}
</style>
