<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <b-row class="no-gutters mb-3">
          <b-col>
            <div class="font-weight-bold ft-20 header-tablepage">
              Custom Object
            </div>
          </b-col>
        </b-row>
        <div class="no-gutters bg-white">
          <b-tabs v-model="tabIndex">
            <b-tab title="Setting">
              <div class="p-3">
                <b-row>
                  <b-col cols="12" md="6">
                    <InputText
                      textFloat="Name"
                      placeholder="Name"
                      type="text"
                      name="name"
                      isRequired
                      :v="$v.form.name"
                      :isValidate="$v.form.name.$error"
                      v-model="form.name"
                  /></b-col>
                  <b-col cols="12" md="6">
                    <InputText
                      textFloat="Sort Order"
                      placeholder="Sort Order"
                      type="number"
                      name="sort_order"
                      v-model="form.sort_order"
                      :v="$v.form.sort_order"
                      :isValidate="$v.form.sort_order.$error"
                  /></b-col>
                  <b-col cols="12" md="6">
                    <InputText
                      textFloat="Limit Per Customer (0=Unlimit)"
                      placeholder="0"
                      type="number"
                      name="limit_per_customer"
                      v-model="form.user_limit"
                  /></b-col>
                  <div class="break-normal"></div>
                  <b-col cols="12" md="6" class="d-inline-flex">
                    <b-form-checkbox
                      id="show_on_register_page"
                      name="show_on_register_page"
                      :value="1"
                      :unchecked-value="0"
                      v-model="form.show_on_register"
                      >Show On Register Page
                    </b-form-checkbox>
                    <b-form-checkbox
                      class="ml-3"
                      id="allow_customer_delete"
                      name="allow_customer_delete"
                      :value="1"
                      :unchecked-value="0"
                      v-model="form.allow_customer_delete"
                      >Allow Customer To Delete
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </div>
              <div class="no-gutters bg-white mt-3 py-2 px-3">
                <b-form-checkbox
                  switch
                  class="radio-active"
                  size="lg"
                  :value="1"
                  :unchecked-value="0"
                  v-model="form.is_active"
                >
                  <span class="ml-2 main-label">Active</span>
                </b-form-checkbox>
              </div>
            </b-tab>
            <b-tab title="Fields Setting"
              ><CustomField :fields="fields" ref="fields" />
            </b-tab>
            <b-tab title="Display">
              <DisplayPreview
                :form="form"
                :fields="fields.filter((el) => el.field_type_id != 8)"
                :imageOptions="fields.filter((el) => el.field_type_id == 8)"
                :data="rawData.field_display"
                ref="fieldDisplay"
                :v="$v.form"
              />
            </b-tab>
            <b-tab title="Data" v-if="$route.params.id != 0">
              <ResponseReport />
            </b-tab>
            <b-tab title="Automation">
              <Automation :data="rawData.automation" ref="automation" />
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <FooterAction @submit="submit" routePath="/custom-object" />
    </div>
  </div>
</template>

<script>
import CustomField from "./components/CustomField.vue";
import DisplayPreview from "./components/DisplayPreview.vue";
import Automation from "./components/Automation.vue";
import ResponseReport from "./components/ResponseReport.vue";
import OtherLoading from "@/components/loading/OtherLoading";
import { required, integer, minValue } from "vuelidate/lib/validators";
export default {
  components: {
    CustomField,
    OtherLoading,
    DisplayPreview,
    Automation,
    ResponseReport,
  },
  data() {
    return {
      tabIndex: 0,
      isLoading: false,
      id: this.$route.params.id,
      fields: [
        {
          field_type_id: 8,
          required: false,
          temp_index: 1,
          img: "",
          validate_text: "Please upload image.",
          name: "Image",
          is_display: 1,
          is_required: 0,
          delete: false,
          field_profile_type_id: 0,
          select_branch: [],
          field_choices: [],
          field_conditions: [
            {
              field_condition_type_id: 1,
              value: "text",
            },
            {
              field_condition_type_id: 2,
              value: "",
            },
            {
              field_condition_type_id: 3,
              value: "",
            },
            {
              field_condition_type_id: 4,
              value: "",
            },
          ],
        },
      ],
      form: {
        name: "",
        description: "",
        user_limit: 0,
        show_on_register: 1,
        response_success: "",
        response_fail: "",
        is_active: 1,
        allow_customer_delete: 1,
        image_banner: "",
        button_label: "",
        sort_order: 0,
      },
      rawData: {
        form: {
          name: "",
          description: "",
          user_limit: 0,
          show_on_register: 1,
          response_success: "",
          response_fail: "",
          is_active: 1,
          allow_customer_delete: 1,
          image_banner: "",
          button_label: "",
          sort_order: 0,
        },
        fields: [],
        field_display: [],
        automation: [],
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        image_banner: {
          required,
        },
        button_label: {
          required,
        },
        sort_order: {
          integer,
          minValue: minValue(0),
        },
      },
      fields: {
        $each: {
          name: {
            required,
          },
        },
      },
    };
  },

  async created() {
    if (this.id != 0) this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const resp = await this.$Axios(
        `${process.env.VUE_APP_API}/CustomObject/${this.id}`
      );
      var index = 1;

      for (const fields of resp.data.detail.fields) {
        fields.temp_index = index;
        index++;
      }

      this.fields = resp.data.detail.fields;
      this.form = resp.data.detail.form;

      this.rawData = resp.data.detail;
      this.isLoading = false;
    },
    async submit() {
      this.$refs.fields.$v.additionnalQuestion.$touch();
      this.$v.form.$touch();
      if (this.$v.form.name.$error) {
        return (this.tabIndex = 0);
      }

      if (this.$refs.fields.$v.additionnalQuestion.$error) {
        return (this.tabIndex = 1);
      }
      if (
        this.$v.form.button_label.$error ||
        this.$v.form.image_banner.$error
      ) {
        return (this.tabIndex = 2);
      }
      this.$EventBus.$emit("showLoading");
      // this.isLoading = true;

      this.rawData.field_display = this.$refs.fieldDisplay.fieldsDisplay
        .filter((el) => el.field_name)
        .map((el, index) => {
          return { ...el, sort_order: index };
        });

      this.form.sort_order = !this.form.sort_order ? 0 : this.form.sort_order;
      this.form.user_limit = !this.form.user_limit ? 0 : this.form.user_limit;
      this.rawData.fields = this.fields.map((el, index) => {
        return { ...el, sort_order: index };
      });
      this.rawData.form = this.form;
      this.rawData.automation = this.$refs.automation.automation.filter(
        (el) => el.automation_id != 0
      );
      const resp = await this.$Axios.post(
        `${process.env.VUE_APP_API}/CustomObject/modify_custom_object`,
        this.rawData
      );
      this.$EventBus.$emit("hideLoading");
      if (resp.data.result == 1) {
        this.successAlert().then(() => this.$router.push("/custom-object"));
      } else {
        this.errorAlert(resp.data.message);
      }
      // this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
